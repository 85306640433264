import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Linkify from 'react-linkify'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Box, Flex, DesktopContainer, MobileContainer } from '../components/ui/Div'
import Button from '../components/ui/Button'
import Spinner from '../components/ui/Spinner'
import Image from '../components/ui/Image'
import { colors, smallBoxShadow } from '../components/ui/helpers.js'
import { urlToString } from '../components/ui/formatters.js'
import countryToIso2 from '../../constants/countryToIso2'
import { H1, H2, H3, SeoH2, SeoH4, Span, Text, LinkSpan } from '../components/ui/Text.js'
import OnlineIcon from '../components/ui/OnlineIcon'

import Layout from '../components/layout'
import SideMenu from '../components/SideMenu'
import DesktopSideBar from '../components/DesktopSideBar'
import Helmet from '../components/Helmet'
import Footer from '../components/Footer'
import Header from '../components/Header'

const FullHeightContainer = styled(Flex)`
  min-height: 100vh;
`

const PrivacyPolicy = ({}) => {
  return (
    <Layout>
      <Helmet
        title={'About | SMS ANDA'}
        lang="en"
        description={`Find out about the internet's most extensive collection of disposable phone numbers | Receive SMS online for free with no ads or registration.`}
      >
        <html lang={'en'} />
      </Helmet>
      <FullHeightContainer flexDirection="column" alignItems="center">
        <Header />

        <Flex maxWidth="900px" pt="60px" flexDirection="column" mb="50px">
          <H1 mb="40px">Privacy Policy </H1>
          <Text fontSize="18px" bold ml="40px" mb="40px">
            Owner contact email: smsandacontact@gmail.com
          </Text>
          <H3 mb="20px" mt="20px">
            Types of Data collected
          </H3>
          <Text>
            This application, either independently or via third parties, collects certain Personal Data including Cookies and Usage Data. Detailed
            information regarding each category of Personal Data gathered is available in this privacy policy's specific sections or in individual
            explanatory texts shown before data collection occurs. Users can willingly provide their Personal Data, while Usage Data is collected
            automatically during the application's use. Unless otherwise indicated, all data requested by this application is compulsory, and failing
            to supply this data could hinder the application's ability to deliver its services. Where the application indicates that providing certain
            data is not mandatory, users may choose not to provide this data, without any impact on the service's availability or functionality. For
            any doubts regarding what Personal Data is compulsory, users are encouraged to reach out to the Owner. This application, along with any
            third-party services used by the application, may use Cookies or other tracking tools to provide the User-requested service, and for any
            other purposes outlined in this document and in the Cookie Policy, if available. Users bear responsibility for any third-party Personal
            Data acquired, disclosed, or shared via this application, and they must confirm that they have received the third party's consent to
            provide the Data to the Owner.
          </Text>
          <H3 mb="20px" mt="20px">
            Methods of processing
          </H3>
          <Text>
            The Owner implements adequate security measures to thwart unauthorized access, revelation, alteration, or illicit destruction of the Data.
            Data processing is performed using computers and/or IT-enabled tools, strictly adhering to organizational procedures and methodologies
            related to the stated purposes. Alongside the Owner, in certain scenarios, the Data may be accessible to specific individuals involved in
            running this Application (such as administration, sales, marketing, legal, and system administration) or to external entities (like
            third-party technical service providers, mail couriers, hosting providers, IT firms, communications agencies) who have been assigned, when
            needed, as Data Processors by the Owner. A regularly updated list of these parties can be obtained from the Owner upon request.{' '}
          </Text>
          <H3 mb="20px" mt="20px">
            Legal basis of processing
          </H3>
          <Text>
            The Owner may process Personal Data relating to Users if one of the following applies: Users have given their consent for one or more
            specific purposes. Note: Under some legislations the Owner may be allowed to process Personal Data until the User objects to such
            processing (“opt-out”), without having to rely on consent or any other of the following legal bases. This, however, does not apply,
            whenever the processing of Personal Data is subject to European data protection law; provision of Data is necessary for the performance of
            an agreement with the User and/or for any pre-contractual obligations thereof; processing is necessary for compliance with a legal
            obligation to which the Owner is subject; processing is related to a task that is carried out in the public interest or in the exercise of
            official authority vested in the Owner; processing is necessary for the purposes of the legitimate interests pursued by the Owner or by a
            third party. In any case, the Owner will gladly help to clarify the specific legal basis that applies to the processing, and in particular
            whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract.
          </Text>
          <H3 mb="20px" mt="20px">
            Place
          </H3>
          <Text>
            The Data is processed at the Owner's operational offices and anywhere else where those involved in the processing are situated. Data
            transfers, which might include transferring the User's Data to a country different from their own, can vary based on the User's location.
            For further information about the processing location of such transferred Data, users can refer to the section detailing the Personal Data
            processing. Users also have the right to know the legal basis for Data transfers to a country outside the European Union or to any
            international organization under public international law or established by two or more countries, like the UN, as well as the security
            precautions taken by the Owner to protect their Data. If such a transfer occurs, Users can learn more by reviewing the pertinent sections
            of this document or by reaching out to the Owner using the contact information provided.
          </Text>
          <H3 mb="20px" mt="20px">
            Retention time
          </H3>
          <Text>
            The processing and storage duration of Personal Data is dictated by the purpose for which they were collected. Hence, Personal Data
            gathered for the execution of a contract between the User and the Owner will be kept until the full completion of the contract. Personal
            Data obtained to fulfill the Owner's legitimate interests will be held for the duration necessary to achieve these interests. Users can
            find detailed information about the Owner's legitimate interests within the corresponding sections of this document or by reaching out to
            the Owner. With user consent, the Owner may be permitted to store Personal Data for a longer time, provided that such consent isn't
            withdrawn. Moreover, in compliance with a legal obligation or an authoritative order, the Owner might be required to retain Personal Data
            for an extended period. Upon reaching the end of the retention period, Personal Data will be deleted. Consequently, rights such as access,
            erasure, rectification, and data portability cannot be invoked post the expiration of the retention period.
          </Text>
          <H3 mb="20px" mt="20px">
            The purposes of processing
          </H3>
          <Text>
            The Data concerning the User is collected to allow the Owner to provide its Service, comply with its legal obligations, respond to
            enforcement requests, protect its rights and interests (or those of its Users or third parties), detect any malicious or fraudulent
            activity, as well as the following: Analytics. For specific information about the Personal Data used for each purpose, the User may refer
            to the section “Detailed information on the processing of Personal Data”. Detailed information on the processing of Personal Data Personal
            Data is collected for the following purposes and using the following services: Analytics
          </Text>

          <H3 mb="20px" mt="20px">
            The rights of Users
          </H3>
          <Text>
            Users have specific rights concerning their Data managed by the Owner. Specifically, Users can: Withdraw Consent: Users can retract their
            consent for processing their Personal Data, which was previously granted. Object to Processing: Users can object to the processing of
            their Data if it is performed on a legal basis other than consent. More information is provided in the relevant section below. Access
            Data: Users can verify if the Owner is processing their Data, gain insight into certain aspects of the processing, and obtain a copy of
            the Data being processed. Verify and Seek Rectification: Users can validate the accuracy of their Data and request updates or corrections.
            Restrict Processing: Under certain conditions, Users can limit the processing of their Data. In this scenario, the Owner will store the
            Data without processing it for any other purposes. Delete or Remove Personal Data: In specific circumstances, Users can request the
            deletion of their Data by the Owner. Data Portability: Users can receive their Data in a structured, commonly used, and machine-readable
            format. If technically possible, they can have it transferred to another controller without any obstacles. This is applicable when the
            Data is processed automatically and the processing is based on User consent, a contract to which the User is a party, or pre-contractual
            obligations. Lodge a Complaint: Users have the right to lodge a complaint with their respective data protection authority. Regarding the
            right to object to processing, if Personal Data is processed for a public interest, an official authority vested in the Owner, or for the
            Owner's legitimate interests, Users can object by providing grounds related to their specific situation to justify the objection. Users
            should note, however, if their Personal Data is processed for direct marketing purposes, they can object to this processing at any time
            without providing any reason. To discover whether the Owner processes Personal Data for direct marketing purposes, Users may refer to the
            relevant sections of this document.
          </Text>
          <H3 mb="20px" mt="20px">
            How to exercise these rights
          </H3>
          <Text>
            Any requests to exercise User rights can be directed to the Owner through the contact details provided in this document. These requests
            can be exercised free of charge and will be addressed by the Owner as early as possible and always within one month. Additional
            information about Data collection and processing
          </Text>
          <H3 mb="20px" mt="20px">
            Legal action
          </H3>
          <Text>
            The User's Personal Data may be used for legal purposes by the Owner in Court or in the stages leading to possible legal action arising
            from improper use of this Application or the related Services. The User declares to be aware that the Owner may be required to reveal
            personal data upon request of public authorities.
          </Text>
          <H3 mb="20px" mt="20px">
            Additional information about User's Personal Data
          </H3>
          <Text>
            In addition to the information contained in this privacy policy, this Application may provide the User with additional and contextual
            information concerning particular Services or the collection and processing of Personal Data upon request.
          </Text>
          <H3 mb="20px" mt="20px">
            System logs and maintenance
          </H3>
          <Text>
            For operation and maintenance purposes, this Application and any third-party services may collect files that record interaction with this
            Application (System logs) use other Personal Data (such as the IP Address) for this purpose.
          </Text>
          <H3 mb="20px" mt="20px">
            Information not contained in this policy
          </H3>
          <Text>
            More details concerning the collection or processing of Personal Data may be requested from the Owner at any time. Please see the contact
            information at the beginning of this document.
          </Text>

          <H3 mb="20px" mt="20px">
            How “Do Not Track” requests are handled
          </H3>
          <Text>
            This Application does not support “Do Not Track” requests. To determine whether any of the third-party services it uses honor the “Do Not
            Track” requests, please read their privacy policies.
          </Text>
          <H3 mb="20px" mt="20px">
            Changes to this privacy policy
          </H3>
          <Text>
            The Owner retains the right to update this privacy policy at any given time. Users will be notified of any changes on this page, within
            this Application, and if possible and legally permissible, through a notice sent via any contact information the Owner has for the User.
            Users are highly advised to frequently review this page, referencing the last modified date listed at the bottom. If the changes impact
            processing activities that were initially based on the User's consent, the Owner will, if necessary, seek fresh consent from the User.
          </Text>
        </Flex>
      </FullHeightContainer>
      <Footer iso2={'en'} />
    </Layout>
  )
}
export default PrivacyPolicy

//http://localhost:8000/united-kingdom/447901614024
// d1uyh0a3eznojw.cloudfront.net/united-kingdom/447901614024
